import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { UserMenu } from '../UserMenu/UserMenu';

export const MobileHeader = () => {
  const [toggle, setToggle] = useReducer((toggle) => !toggle, false);
  return (
    <header className="flex justify-between items-center px-4 py-4 md:hidden">
      <div className="text-2xl font-bold">
        <Link to="/dashboard">Q</Link>
      </div>
      <div>
        <button
          type="button"
          className="block w-8 h-8 transition-colors hover:bg-gray-200 rounded-md flex justify-center items-center text-gray-500"
          onClick={setToggle}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            viewBox="0 -960 960 960"
            fill="currentColor"
          >
            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
          </svg>
        </button>
        <div className="relative">
          <UserMenu
            visible={toggle}
            className={
              'absolute top-0 right-0 w-48 origin-top-right rounded-md shadow-lg md:w-56 mt-2'
            }
          />
        </div>
      </div>
    </header>
  );
};
