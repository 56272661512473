import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';

type ReturnPathState = {
  path: string | null;
  setPath: (token: string | null) => void;
};

export const useReturnPathStore = create<ReturnPathState>()(devtools(
  persist((set) => ({
    path: null,
    setPath: (path) => set({ path }),
  }), {
    name: 'quorlix.return-path',
    version: 0,
    storage: createJSONStorage(() => sessionStorage),
  }),
));
