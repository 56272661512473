export type FetchApiConfig = {
  baseUrl: string;
  token: string;
};

export type FetchApiResponse<T> = {
  ok: boolean;
  status: number;
  statusText: string;
  data: T;
  problem?: string;
};

export type FetchFilterOptions = {
  filters: Record<string, string>;
};

export type FetchPaginationOptions = {
  cursor: string;
  limit: number;
};

export type FetchQueryOptions = Partial<
  FetchFilterOptions & FetchPaginationOptions & Record<string, any>
>;

export class FetchApi {
  constructor(private config: Partial<FetchApiConfig> = {}) {
    this.config = {
      baseUrl: undefined,
      token: undefined,
      ...config
    };
  }

  public setToken(token: string) {
    this.config.token = token;
  }

  public async get<T>(
    url: string,
    params: FetchQueryOptions = {},
    options: RequestInit = {}
  ): Promise<FetchApiResponse<T>> {
    return this.fetch(this.searchify(url, params), options);
  }

  public async post<T, B = object>(
    url: string,
    body: B,
    params: FetchQueryOptions = {},
    options: RequestInit = {}
  ): Promise<FetchApiResponse<T>> {
    return this.fetch(
      this.searchify(url, params),
      this.mergeRequestInit(
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        },
        options
      )
    );
  }

  public async put<T, B = object>(
    url: string,
    body: B,
    params: FetchQueryOptions = {},
    options: RequestInit = {}
  ): Promise<FetchApiResponse<T>> {
    return this.fetch(
      this.searchify(url, params),
      this.mergeRequestInit(
        {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        },
        options
      )
    );
  }

  public async patch<T, B = object>(
    url: string,
    body: B,
    params: FetchQueryOptions = {},
    options: RequestInit = {}
  ): Promise<FetchApiResponse<T>> {
    return this.fetch(
      this.searchify(url, params),
      this.mergeRequestInit(
        {
          method: 'PATCH',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        },
        options
      )
    );
  }

  public async delete<T>(
    url: string,
    params: FetchQueryOptions = {},
    options: RequestInit = {}
  ): Promise<FetchApiResponse<T>> {
    return this.fetch(
      this.searchify(url, params),
      this.mergeRequestInit(
        {
          method: 'DELETE'
        },
        options
      )
    );
  }

  protected async fetch<T>(
    url: string,
    options: RequestInit = {}
  ): Promise<FetchApiResponse<T>> {
    try {
      const isAbsoluteUrl = isValidAbsoluteUrl(url);
      const input = isAbsoluteUrl ? url : `${this.config.baseUrl}${url}`;

      const response = await fetch(input, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: this.config.token
            ? `Bearer ${this.config.token}`
            : undefined!
        }
      });

      let data = null as T;
      try {
        data = await response.json();
      } catch (error) {
        //
      }

      return {
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        data: data as T,
        problem: makeProblem(response.status)
      };
    } catch (error) {
      if (options.signal?.aborted) {
        return {
          ok: false,
          status: 0,
          statusText: 'Request aborted',
          data: {} as T,
          problem: 'ABORTED'
        };
      }
      console.error('FetchApi error:', error);
      return {
        ok: false,
        status: 0,
        statusText: 'Network error',
        data: {} as T,
        problem: 'NETWORK_ERROR'
      };
    }
  }

  protected searchify(url: string, search: FetchQueryOptions = {}) {
    if (Object.keys(search).length === 0) {
      return url;
    }

    for (const key in search) {
      if (search[key] === undefined || search[key] === null) {
        delete search[key];
      }

      if (typeof search[key] === 'object') {
        search[key] = JSON.stringify(search[key]);
        if (search[key] === '{}') {
          delete search[key];
          continue;
        }
      }
    }

    const searchParams = new URLSearchParams(search);

    return `${url}?${searchParams.toString()}`;
  }

  protected mergeRequestInit(
    options: RequestInit = {},
    overrides: RequestInit = {}
  ): RequestInit {
    return {
      ...options,
      ...overrides
    };
  }
}

const makeProblem = (status: number) => {
  if (status >= 200 && status < 400) {
    return 'NONE';
  } else if (status >= 400 && status < 500) {
    return 'CLIENT_ERROR';
  } else if (status >= 500 && status < 600) {
    return 'SERVER_ERROR';
  }

  return 'NONE';
};

const isValidAbsoluteUrl = (url: string) => {
  try {
    const protocol = new URL(url).protocol;
    return !!protocol;
  } catch (error) {
    return false;
  }
};
