import { create } from 'zustand';
import { persist, devtools, subscribeWithSelector } from 'zustand/middleware';

type User = {
  id: string;
  email: string;
  plans: string[];
  role: 'user' | 'admin';
};

type AuthState = {
  token: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: User | null;
  setToken: (token: string) => void;
  removeToken: () => void;
  setUser: (user: User) => void;
};

// todo: transform what we store in the user
export const useAuthStore = create<AuthState>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set) => ({
          token: null,
          user: null,
          setToken: (token) => set({ token }),
          removeToken: () => set({ token: null, user: null }),
          setUser: (user) => set({ user })
        }),
        {
          name: 'quorlix.auth',
          version: 0
        }
      )
    )
  )
);
