import { useReducer } from 'react';
import { Link } from 'react-router-dom';
import { NavItems } from './NavItems';
import { UserMenu } from '../../6_layouts/ProtectedLayout/UserMenu/UserMenu';

export const SidebarNav = () => {
  const [toggle, setToggle] = useReducer((toggle) => !toggle, false);
  return (
    <aside className="hidden md:flex w-[76px] min-h-[480px] h-[100vh] fixed top-0 left-0 flex-col justify-between items-center">
      <div className="py-4 text-3xl text-blue-700">
        <Link to="/dashboard">Q</Link>
      </div>
      <NavItems />
      <div className="py-4 relative">
        <button
          type="button"
          className="block w-12 h-12 transition-colors hover:bg-gray-200 rounded-md flex justify-center items-center text-gray-500"
          onClick={setToggle}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            viewBox="0 -960 960 960"
            fill="currentColor"
          >
            <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
          </svg>
        </button>
        <UserMenu
          visible={toggle}
          className={
            'absolute bottom-4 left-[76px] w-48 mt-2 origin-top-right  md:w-56'
          }
        />
      </div>
    </aside>
  );
};
