import { useAuthStore } from '../../store/auth-store';
import { FetchApiResponse } from '../fetch-api';

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { ok: false; kind: 'timeout'; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { ok: false; kind: 'cannot-connect'; temporary: true }
  /**
   * Request was cancelled.
   */
  | { ok: false; kind: 'request-aborted'; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { ok: false; kind: 'server' }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { ok: false; kind: 'unauthorized' }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { ok: false; kind: 'forbidden' }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { ok: false; kind: 'not-found' }
  /**
   * All other 4xx series errors.
   */
  | { ok: false; kind: 'rejected' }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { ok: false; kind: 'unknown'; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { ok: false; kind: 'bad-data' }
  /**
   * Rate limit hit
   */
  | { ok: false; kind: 'rate-limited' }
  /**
   * Failed to validate the data.
   */
  | { ok: false; kind: 'validation-error'; errors: string[] };

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getGeneralApiProblem(
  response: FetchApiResponse<any>
): GeneralApiProblem | null {
  switch (response.problem) {
    case 'ABORTED':
      return { ok: false, kind: 'request-aborted', temporary: true };
    case 'CONNECTION_ERROR':
    case 'NETWORK_ERROR':
      return { ok: false, kind: 'cannot-connect', temporary: true };
    case 'TIMEOUT_ERROR':
      // alert.HttpTimeout()
      return { ok: false, kind: 'timeout', temporary: true };
    case 'SERVER_ERROR':
      // alert.HttpTimeout()
      return { ok: false, kind: 'server' };
    case 'UNKNOWN_ERROR':
      return { ok: false, kind: 'unknown', temporary: true };
    case 'CLIENT_ERROR':
      switch (response.status) {
        case 401:
          useAuthStore.getState().removeToken();
          return { ok: false, kind: 'unauthorized' };
        case 403:
          // alert.HttpForbidden()
          return { ok: false, kind: 'forbidden' };
        case 404:
          return { ok: false, kind: 'not-found' };
        case 422:
          return {
            ok: false,
            kind: 'validation-error',
            errors: response.data.errors
          };
        case 429:
          return { ok: false, kind: 'rate-limited' };
        default:
          return { ok: false, kind: 'rejected' };
      }
    case 'CANCEL_ERROR':
      return null;
  }

  return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GeneralApiData<T = any> {
  ok: true;
  kind: 'ok';
  data: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GeneralApiResponse<T = any> = GeneralApiProblem | GeneralApiData<T>;

export function getGeneralApiResponse<T>(
  response: FetchApiResponse<T>
): GeneralApiResponse<T> {
  if (response.ok) {
    return { ok: true, kind: 'ok', data: response.data };
  }

  return getGeneralApiProblem(response) ?? { ok: false, kind: 'bad-data' };
}
