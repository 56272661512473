import Config from '../../config';
import {
  ApiConfig,
  AuthLoginResponse,
  AuthUserResponse,
  InstrumentType,
  PaginatedResponse,
  StatsPeriod,
  StatsSort,
  TickerStatsResponse,
  PostType,
  ReportType,
  StatsQueryResponse
} from './api.types';
import { useAuthStore } from '../../store/auth-store';
import { FetchApi, FetchQueryOptions } from '../fetch-api';
import { getGeneralApiResponse } from './apiProblem';

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL!,
  timeout: 10000
};

export class ApiBase {
  fetch: FetchApi;
  config: ApiConfig;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.fetch = new FetchApi({
      baseUrl: this.config.url,
      token: useAuthStore.getState().token!
    });

    useAuthStore.subscribe(
      (s) => s.token,
      async (c, p) => {
        console.log('token changed', c, p);
        if (c !== p) {
          this.setToken(c!);
        }
      }
    );
  }

  setToken(token: string) {
    this.fetch.setToken(token);
  }
}

export class Api extends ApiBase {
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config);
  }

  // start auth functions
  async login(email: string, password: string) {
    return this.fetch
      .post<AuthLoginResponse>('/v1/auth/login', { email, password })
      .then(getGeneralApiResponse);
  }

  async user() {
    return this.fetch
      .get<AuthUserResponse>('/v1/auth')
      .then(getGeneralApiResponse);
  }

  // start report functions

  async overview(period: StatsPeriod = 'day') {
    return this.fetch
      .get<TickerStatsResponse[]>('/v1/overview/basic', { period })
      .then(getGeneralApiResponse);
  }

  async overview2(period: StatsPeriod = 'day') {
    return this.fetch
      .get<StatsQueryResponse[]>('/v1/overview/basic', { period })
      .then(getGeneralApiResponse);
  }

  async graphs(period: StatsPeriod = 'day', sort: StatsSort = 'amount') {
    return this.fetch
      .get<TickerStatsResponse[]>('/v1/graphs', { period, sort })
      .then(getGeneralApiResponse);
  }

  // tickers
  async listInstruments(query?: FetchQueryOptions, overrides?: RequestInit) {
    return this.fetch
      .get<
        PaginatedResponse<InstrumentType>
      >('/v1/instruments', query, overrides)
      .then(getGeneralApiResponse);
  }

  async getInstrument(id: number) {
    return this.fetch
      .get<InstrumentType>('/v1/instruments/' + id)
      .then(getGeneralApiResponse);
  }

  // posts
  async listPosts(query?: FetchQueryOptions, overrides?: RequestInit) {
    return this.fetch
      .get<PaginatedResponse<PostType>>('/v1/posts', query, overrides)
      .then(getGeneralApiResponse);
  }

  async testList(query?: FetchQueryOptions, overrides?: RequestInit) {
    return this.fetch
      .get<
        PaginatedResponse<{ id: number; i: number }>
      >('/v1/test', query, overrides)
      .then(getGeneralApiResponse);
  }

  // reports
  async listReports(query?: FetchQueryOptions, overrides?: RequestInit) {
    return this.fetch
      .get<PaginatedResponse<ReportType>>('/v1/reports', query, overrides)
      .then(getGeneralApiResponse);
  }
}

export const api = new Api();
