import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { useAuthStore } from './store/auth-store';
import { useCallback, useEffect } from 'react';
import { api } from './services/api/api';

function App() {
  const updateUser = useCallback(async () => {
    const response = await api.user();
    if (response.ok) {
      useAuthStore.getState().setUser(response.data);
    }
  }, []);

  useEffect(() => {
    api.setToken(useAuthStore.getState().token!);
    updateUser();

    const subscribe = useAuthStore.subscribe(
      (s) => s.token,
      async (c, p) => {
        if (c !== p) {
          api.setToken(c!);
        }

        if (c) {
          updateUser();
        }
      }
    );

    return subscribe;
  }, [updateUser]);

  return <RouterProvider router={router} />;
}

export default App;
