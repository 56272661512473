import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from './app/5_pages/ErrorPage/ErrorPage';
import { ProtectedLayout } from './app/6_layouts/ProtectedLayout/ProtectedLayout';
import { GuestLayout } from './app/6_layouts/GuestLayout/GuestLayout';
import { HomePage } from './app/5_pages/HomePage/HomePage';
import { AdminLayout } from './app/6_layouts/AdminLayout/AdminLayout';

export const router = createBrowserRouter([
  {
    element: <GuestLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '/login',
        lazy: async () =>
          import('./app/5_pages/LoginPage/LoginPage').then((module) => ({
            Component: module.LoginPage
          }))
      }
    ]
  },
  {
    element: <ProtectedLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/dashboard',
        lazy: async () =>
          import('./app/5_pages/DashboardPage/DashboardPage2').then(
            (module) => ({ Component: module.DashboardPage2 })
          )
      },
      {
        path: '/reports',
        lazy: async () =>
          import('./app/5_pages/ReportsPage/ReportsPage').then((module) => ({
            Component: module.ReportsPage
          }))
      },
      {
        path: '/posts',
        lazy: async () =>
          import('./app/5_pages/PostsPage/PostsPage').then((module) => ({
            Component: module.PostsPage
          }))
      },
      {
        path: '/instruments',
        lazy: async () =>
          import('./app/5_pages/InstrumentsPage/InstrumentsPage').then(
            (module) => ({
              Component: module.InstrumentsPage
            })
          )
      },
      {
        path: '/instruments/:id',
        lazy: async () =>
          import('./app/5_pages/InstrumentPage/InstrumentPage').then(
            (module) => ({
              Component: module.InstrumentPage
            })
          )
      }
    ]
  },
  {
    element: <AdminLayout />,
    errorElement: <ErrorPage />,
    path: '/hash',
    children: [
      {
        index: true,
        lazy: async () =>
          import(
            './app/5_pages/Admin/AdminDashboardPage/AdminDashboardPage'
          ).then((module) => ({ Component: module.AdminDashboardPage }))
      },
      {
        path: 'tickers',
        lazy: async () =>
          import('./app/5_pages/Admin/AdminTickersPage/AdminTickersPage').then(
            (module) => ({ Component: module.AdminTickersPage })
          )
      },
      {
        path: 'ticker-aliases',
        lazy: async () =>
          import(
            './app/5_pages/Admin/AdminTickerAliasesPage/AdminTickerAliasesPage'
          ).then((module) => ({ Component: module.AdminTickerAliasesPage }))
      },
      {
        path: 'datasources',
        lazy: async () =>
          import(
            './app/5_pages/Admin/AdminDatasourcesPage/AdminDatasourcesPage'
          ).then((module) => ({ Component: module.AdminDatasourcesPage }))
      },
      {
        path: 'posts',
        lazy: async () =>
          import('./app/5_pages/Admin/AdminPostsPage/AdminPostsPage').then(
            (module) => ({ Component: module.AdminPostsPage })
          )
      },
      {
        path: 'graphs',
        lazy: async () =>
          import('./app/5_pages/Admin/GraphsPage/GraphsPage').then(
            (module) => ({
              Component: module.GraphsPage
            })
          )
      },
      {
        path: 'trainer',
        lazy: async () =>
          import('./app/5_pages/Admin/AdminTrainerPage/AdminTrainerPage').then(
            (module) => ({ Component: module.AdminTrainerPage })
          )
      }
    ]
  }
]);
