import clsx from 'clsx';
import { FC } from 'react';
import { useUser } from '../../../../hooks/useUser';
import { Link } from 'react-router-dom';

type UserMenuProps = {
  visible: boolean;
  className?: string;
};

export const UserMenu: FC<UserMenuProps> = ({ visible, className }) => {
  const { isAdmin, logOut } = useUser();
  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          'py-1 bg-white rounded-md shadow-xs border',
          visible ? 'block' : 'hidden'
        )}
      >
        {isAdmin && (
          <Link
            to="/hash"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Admin Dashboard
          </Link>
        )}
        <Link
          to="/profile"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Profile
        </Link>
        <Link
          to="/settings"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Settings
        </Link>
        <Link
          onClick={logOut}
          to="/login"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Logout
        </Link>
      </div>
    </div>
  );
};
