import { create } from 'zustand';
import { persist, devtools, subscribeWithSelector } from 'zustand/middleware';

type ToggleState = {
  toggles: Record<string, boolean>;
  toggle: (key: string) => void;
};

// todo: transform what we store in the user
const useToggleStore = create<ToggleState>()(
  subscribeWithSelector(
    devtools(
      persist(
        (set) => ({
          toggles: {},
          toggle(key) {
            set((state) => ({
              toggles: {
                ...state.toggles,
                [key]: !state.toggles[key]
              }
            }));
          }
        }),
        {
          name: 'quorlix.toggle',
          version: 0
        }
      )
    )
  )
);

export const usePersistentToggle = (key: string, initialState = false) => {
  const value = useToggleStore((state) => state.toggles[key]);
  const toggle = useToggleStore((state) => state.toggle);

  return [value ?? initialState, () => toggle(key)] as const;
};
