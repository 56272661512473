import { useCallback, useMemo } from 'react';
import { useAuthStore } from '../store/auth-store';

export const useUser = () => {
  const { token, user, removeToken } = useAuthStore();

  const isAuthenticated = useMemo(() => !!token && !!user, [token, user]);
  const isAdmin = useMemo(() => user?.role === 'admin', [user]);

  const hasRole = useCallback(
    (role: 'user' | 'admin') => user?.role === role,
    [user]
  );

  const hasPlan = useCallback(
    (plan: string) => user?.plans.includes(plan),
    [user]
  );

  return {
    isAuthenticated,
    isAdmin,
    hasRole,
    hasPlan,
    user,
    logOut: removeToken
  };
};
