import { FC } from 'react';
import { Link, useRouteError } from 'react-router-dom';

type ThatError = Error & { statusText: string; status: number };

export default function ErrorPage() {
  const error = useRouteError() as ThatError;

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <h1>Oops!</h1>
      <ErrorRenderer error={error} />
      <p>
        <Link to="/">Go back to the home page</Link>
      </p>
    </div>
  );
}

type ErrorRendererProps = {
  error: ThatError;
};

const ErrorRenderer: FC<ErrorRendererProps> = ({ error }) => {
  if (error.status === 404) {
    return <p>This page doesn't exist!</p>;
  }

  if (error.status === 401) {
    return <p>You aren't authorized to see this</p>;
  }

  if (error.status === 503) {
    return <p>Looks like our API is down</p>;
  }

  if (error.status === 418) {
    return <p>🫖</p>;
  }

  return <p>Something went wrong</p>;
};
