import clsx from 'clsx';
import { useReducer } from 'react';
import { Link } from 'react-router-dom';

type LinkType = {
  to: string;
  title: string;
};
const LINKS: LinkType[] = [
  {
    to: '/hash',
    title: 'Dashboard'
  },
  {
    to: '/hash/datasources',
    title: 'Datasources'
  },
  {
    to: '/hash/graphs',
    title: 'Graphs'
  },
  {
    to: '/hash/posts',
    title: 'Posts'
  },
  {
    to: '/hash/tickers',
    title: 'Tickers'
  },
  {
    to: '/hash/trainer',
    title: 'Trainer'
  }
];

export const AdminHeaderNav = () => {
  const [toggle, setToggle] = useReducer((toggle) => !toggle, false);
  const [userDropdown, setUserDropdown] = useReducer(
    (toggle) => !toggle,
    false
  );
  return (
    <nav className="w-full h-12 flex flex-wrap items-center justify-between">
      <Link
        to="/dashboard"
        className="flex items-center space-x-3 rtl:space-x-reverse"
      >
        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
          Quorlix Admin
        </span>
      </Link>
      <div className="flex flex-row justify-between space-x-4">
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          onClick={setToggle}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div className="flex flex-row justify-between items-center space-x-8">
          <div
            className={clsx(
              'w-full absolute top-11 left-0 right-0 md:block md:w-auto md:static',
              toggle ? 'block' : 'hidden'
            )}
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 dark:border-gray-700">
              {LINKS.map((link) => (
                <li key={link.to}>
                  <Link
                    to={link.to}
                    className="block py-2 px-3 text-blue-700 rounded hover:bg-gray-500 md:hover:bg-transparent md:border-0 md:text-white md:hover:text-blue-700 md:p-0"
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="relative">
            <button
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={setUserDropdown}
            >
              <span className="sr-only">Open profile</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </button>
            <div className="absolute top-11 right-0 w-48 mt-2 origin-top-right rounded-md shadow-lg md:w-56">
              <div
                className={clsx(
                  'py-1 bg-white rounded-md shadow-xs',
                  userDropdown ? 'block' : 'hidden'
                )}
              >
                <Link
                  to="/dashboard"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Dashboard
                </Link>
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Profile
                </Link>
                <Link
                  to="/settings"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Settings
                </Link>
                <Link
                  to="/logout"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
