import { FC } from 'react';
import { Button } from './Button/Button';
import { usePersistentToggle } from '../../hooks/usePersistentToggle';

export const BetaBanner: FC = () => {
  const [isBannerHidden, toggleBanner] = usePersistentToggle('beta-banner');
  return (
    <div className="mx-4 md:container md:mx-auto rounded bg-red-200 mb-8 mt-8 p-4">
      <p>
        Quorlix AI is a prediction model detecting anomalies in financial
        instruments using activity from media and social networks. We then
        generate report and sends alerts to users so they can take action in a
        timely manner. Report summaries are generated using Gemini LLM models.
      </p>
      <p className="m-0">
        This is a <strong>beta version</strong> of the application. Please
        report any issues to the support team.
      </p>
      {!isBannerHidden ? (
        <>
          <div className="m-0 mt-2">
            While in BETA:
            <ul className="list-disc pl-6">
              <li>
                Anomaly scoring model is <strong>trained on daily data</strong>{' '}
                only.
              </li>
              <li>
                Scoring model tracks only <strong>cryptocurrency</strong>{' '}
                instruments at the moment.
              </li>
              <li>
                Scoring model may be changed or updated without prior notice
                couple times a day. Please do not rely on the results and it's
                consistency.
              </li>
              <li>
                Alerts will not be pushed to your device and emails will not be
                sent even if you will choose so.
              </li>
              <li>
                The application may not be optimized for mobile devices. Please
                use a desktop browser.
              </li>
            </ul>
          </div>

          <div className="mt-4 flex flex-row justify-end items-center">
            <Button variant="clean" onClick={toggleBanner}>
              Got it
            </Button>
          </div>
        </>
      ) : (
        <>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleBanner();
            }}
          >
            Expand beta notes...
          </a>
        </>
      )}
    </div>
  );
};
