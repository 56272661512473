import BaseConfig from './config.base';

// let ExtraConfig = ProdConfig

// if (process.env.NODE_ENV === 'development') {
//   ExtraConfig = DevConfig
// }

const Config = { ...BaseConfig };

export default Config;
