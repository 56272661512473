import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from '../../../store/auth-store';
import { useReturnPathStore } from '../../../store/return-path-store';

const preparePath = (path: string | null) => {
  if (!path) {
    return '/dashboard';
  }

  return path.startsWith('/') ? path : '/dashboard';
};

export const GuestLayout: FC = () => {
  const { token } = useAuthStore();
  const { path } = useReturnPathStore();

  if (token) {
    return <Navigate to={preparePath(path)} />;
  }

  return <Outlet />;
};
