import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AdminHeaderNav } from './components/HeaderNav/AdminHeaderNav';
import { useUser } from '../../../hooks/useUser';

export const AdminLayout: FC = () => {
  const { isAuthenticated, isAdmin } = useUser();
  const path = useLocation().pathname;

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login', search: `return=${path}` }} />;
  }

  if (!isAdmin) {
    throw new Response('Not Found', { status: 404 });
  }

  return (
    <div className="h-full flex flex-col justify-between">
      <header className="w-full h-12 bg-gray-800 text-white flex justify-between items-center px-4">
        <AdminHeaderNav />
      </header>
      <div className="h-full w-full flex flex-col justify-between">
        <div className="w-full flex flex-row justify-center flex-1">
          {/* <aside className="shrink grow min-w-32 max-w-64 bg-gray-200 dark:bg-gray-800 text-white p-4">
            sidebar...
          </aside> */}
          <main className="w-full flex-1">
            <Outlet />
          </main>
        </div>
        {/* <footer className="w-full h-16 bg-gray-800 text-white flex justify-center items-center">
          <p>Footer</p>
        </footer> */}
      </div>
    </div>
  );
};
