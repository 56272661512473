import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../../../hooks/useUser';
import { SidebarNav } from '../../1_molecules/HeaderNav/SidebarNav';
import styles from './ProtectedLayout.module.css';
import { NavItems } from '../../1_molecules/HeaderNav/NavItems';
import { MobileHeader } from './MobileHeader/MobileHeader';
import { BetaBanner } from '../../1_molecules/BetaBanner';

export const ProtectedLayout: FC = () => {
  const { isAuthenticated } = useUser();
  const path = useLocation().pathname;

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login', search: `return=${path}` }} />;
  }

  return (
    <>
      <MobileHeader />
      <SidebarNav />
      <div className={styles.main}>
        <BetaBanner />
        <Outlet />
      </div>
      <div className={styles.footer}>
        <NavItems />
      </div>
    </>
  );
};
